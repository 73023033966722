import React, { useEffect, useMemo } from 'react';
import { Link, Paper } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { fetchLatestTasks } from '../../redux/actions/taskActions';
import RyffineTable from '../common/RyffineTable';
import { formatDateStr } from '../../core/services/commonService';
import { TASKS_ROUTE } from '../../core/constants';
import { generateStatusField } from '../common/Utility';
import { APP_ENV } from '../../config';

const getUUIDForCollegeBoard = task => {
  if (task.converter === 'evaluation') {
    const config = JSON.parse(task.config || '{}');

    if (Object.prototype.hasOwnProperty.call(config, 'friendly_name') && config.friendly_name !== '') {
      return <Link to={`${TASKS_ROUTE}/${task.id}`} component={RouterLink}>{config.friendly_name}</Link>;
    }
  }

  return <Link to={`${TASKS_ROUTE}/${task.id}`} component={RouterLink}>{task.source.split('/').pop()}</Link>;
};

const getTaskLink = task => <Link to={`${TASKS_ROUTE}/${task.id}`} component={RouterLink}>{task.id}</Link>;

const generateTableRows = tasks => (
  tasks
    .map((task, index) => {
      if (APP_ENV === 'collegeBoard') {
        return {
          id: index,
          uuid: {
            value: task.id,
            displayValue: getUUIDForCollegeBoard(task),
          },
          converter: task.converter,
          tag: task.tag,
          status: {
            value: task.status,
            displayValue: generateStatusField(task.status),
          },
          created: {
            value: task.created,
            displayValue: formatDateStr(task.created) || 'N/A',
          },
        };
      }
      return {
        id: index,
        uuid: {
          value: task.id,
          displayValue: getTaskLink(task),
        },
        converter: task.converter,
        tag: task.tag,
        status: {
          value: task.status,
          displayValue: generateStatusField(task.status),
        },
        created: {
          value: task.created,
          displayValue: formatDateStr(task.created) || 'N/A',
        },
      };
    })
);

const columns = APP_ENV === 'collegeBoard' ? [
  {
    field: 'uuid', headerName: 'Friendly Name / Source filename', sortable: true, flex: 1,
  },
  {
    field: 'converter', headerName: 'Type', sortable: true, flex: 0.5,
  },
  {
    field: 'tag', headerName: 'Release', sortable: true, flex: 0.5,
  },
  {
    field: 'status', headerName: 'Status', sortable: true, flex: 0.5,
  },
  {
    field: 'created', headerName: 'Date created', sortable: true, flex: 0.75,
  },
] : [
  {
    field: 'uuid', headerName: 'UUID', sortable: true, flex: 1,
  },
  {
    field: 'converter', headerName: 'Type', sortable: true, flex: 0.5,
  },
  {
    field: 'tag', headerName: 'Release', sortable: true, flex: 0.5,
  },
  {
    field: 'status', headerName: 'Status', sortable: true, flex: 0.5,
  },
  {
    field: 'created', headerName: 'Date created', sortable: true, flex: 0.75,
  },
];

export default function LatestTasks() {
  const dispatch = useDispatch();

  const { tasks, loading } = useSelector(state => state.task.latestTasks);

  useEffect(() => {
    dispatch(fetchLatestTasks());
  }, [dispatch]);

  const tableRows = useMemo(() => generateTableRows(tasks), [tasks]);

  return (
    <RyffineTable
      tableContainerProps={{
        className: `${RyffineTable.defaultProps.tableContainerProps.className} latest-tasks`,
        component: Paper,
      }}
      lastRecordWithoutBorder
      displayPagination={false}
      columns={columns}
      loading={loading}
      rows={tableRows}
      initSorting={{
        order: 'desc',
        field: 'created',
      }}
    />
  );
}
