import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import {
  Grid,
  makeStyles,
  Table,
  TableContainer,
  MenuItem,
  InputLabel,
  Select,
  FormControl,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { fetchTaskReportLogs } from '../../redux/actions/taskActions';
import { isObjectEmpty, isInclude } from '../../core/services/commonService';
import { TaskEntity } from '../../core/entities';
import Loader from '../common/Loader';
import LoadingLink from '../common/LoadingLink';
import { useQuery } from '../common/Utility';
import DiffDisplayer from '../common/DiffDisplayer';

// TODO: fixed importing css for diff displayer
const diffStyles = `
:root {
  --diff-background-color: initial;
  --diff-text-color: initial;
  --diff-font-family: Consolas, Courier, monospace;
  --diff-selection-background-color: #b3d7ff;
  --diff-gutter-insert-background-color: #d6fedb;
  --diff-gutter-delete-background-color: #fadde0;
  --diff-gutter-selected-background-color: #fffce0;
  --diff-code-insert-background-color: #eaffee;
  --diff-code-delete-background-color: #fdeff0;
  --diff-code-insert-edit-background-color: #c0dc91;
  --diff-code-delete-edit-background-color: #f39ea2;
  --diff-code-selected-background-color: #fffce0;
  --diff-omit-gutter-line-color: #cb2a1d;
}

.diff {
  background-color: var(--diff-background-color);
  color: var(--diff-text-color);
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;
}

.diff::selection {
  background-color: var(--diff-selection-background-color);
}

.diff td {
  vertical-align: top;
  padding-top: 0;
  padding-bottom: 0;
}

.diff-line {
  line-height: 1.5;
  font-family: var(--diff-font-family);
}

.diff-gutter > a {
  color: inherit;
  display: block;
}

.diff-gutter {
  padding: 0 1ch;
  text-align: right;
  cursor: pointer;
  user-select: none;
}

.diff-gutter-insert {
  background-color: var(--diff-gutter-insert-background-color);
}

.diff-gutter-delete {
  background-color: var(--diff-gutter-delete-background-color);
}

.diff-gutter-omit {
  cursor: default;
}

.diff-gutter-selected {
  background-color: var(--diff-gutter-selected-background-color);
}

.diff-code {
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-all;
  padding: 0 0 0 0.5em;
}

.diff-code-edit {
  display: inline-block;
  color: inherit;
}

.diff-code-insert {
  background-color: var(--diff-code-insert-background-color);
}

.diff-code-insert .diff-code-edit {
  background-color: var(--diff-code-insert-edit-background-color);
}

.diff-code-delete {
  background-color: var(--diff-code-delete-background-color);
}

.diff-code-delete .diff-code-edit {
  background-color: var(--diff-code-delete-edit-background-color);
}

.diff-code-selected {
  background-color: var(--diff-code-selected-background-color);
}

.diff-widget-content {
  vertical-align: top;
}

.diff-gutter-col {
  width: 7ch;
}

.diff-gutter-omit {
  height: 0;
}

.diff-gutter-omit:before {
  content: ' ';
  display: block;
  white-space: pre;
  width: 2px;
  height: 100%;
  margin-left: 4.6ch;
  overflow: hidden;
  background-color: var(--diff-omit-gutter-line-color);
}

.diff-decoration {
  line-height: 1.5;
  user-select: none;
}

.diff-decoration-content {
  font-family: var(--diff-font-family);
  padding: 0;
}`;

const filterBySearch = (events, fields, searchValue) => events.filter(
  event => {
    const values = fields.map(field => event.message[field]);

    return isInclude(
      values.join('\n'),
      searchValue,
    );
  },
);

const useStyles = makeStyles(theme => ({
  tableHeadRow: {
    backgroundColor: theme.palette.action.hover,
  },
  tableRow: {
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

const titleCase = str => {
  const splitStr = str.toLowerCase().split(' ');

  for (let i = 0; i < splitStr.length; i += 1) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }

  return splitStr.join(' ');
};

const parseEvents = events => {
  const returnEvents = [];

  events.map(event => returnEvents.push({
    timestamp: event.timestamp,
    message: JSON.parse(event.message),
  }));

  return returnEvents;
};

function TaskDetailsReportLogs({ task, config }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const query = useQuery();
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const [nextForwardToken, setNextForwardToken] = useState('');
  const [loadingNewerEvents, setLoadingNewerEvents] = useState(false);
  const [newerEventsExist, setNewerEventsExist] = useState(true);
  const [reportName, setReportName] = useState(query.get('report') || config.default);
  const [searchValue, setSearchValue] = useState('');
  const logsExist = events.length > 0;

  const fetchReportLogs = (name, token) => {
    const encoded = encodeURIComponent(`{ $.report_name = "${name}" }`.replace(/\+/g, ' '));

    return dispatch(fetchTaskReportLogs(task.id, encoded, token));
  };

  if (query.get('report') && reportName !== query.get('report')) {
    setReportName(query.get('report'));
  }

  useEffect(() => {
    if (!query.get('report')) {
      history.push(`${location.pathname}#tab=${query.get('tab')}&report=${config.default}`);
    }

    if (!isObjectEmpty(task)) {
      setLoading(true);

      fetchReportLogs(reportName)
        .then(res => {
          setEvents(parseEvents(res.events));
          setNextForwardToken(res.nextForwardToken);
        })
        .finally(() => setLoading(false));
    }
  }, [reportName]);

  let fields = [];

  if (logsExist) {
    fields = Object.keys(events[0].message).filter(name => name !== 'report_name');
  }

  const filteredEvents = useMemo(
    () => filterBySearch(events, fields, searchValue),
    [events, searchValue],
  );

  const onReportChange = name => {
    setLoading(true);
    setReportName(name);
    setNewerEventsExist(true);

    history.push(`${location.pathname}#tab=${query.get('tab')}&report=${name === '' ? '/' : name}`);
  };

  const handleGetNewerEvents = () => {
    setLoadingNewerEvents(true);

    fetchReportLogs(reportName, nextForwardToken)
      .then(res => {
        setEvents([...events, ...parseEvents(res.events)]);

        if (res.nextForwardToken === '') {
          setNewerEventsExist(false);
        }

        setNextForwardToken(res.nextForwardToken);
      })
      .finally(() => setLoadingNewerEvents(false));
  };

  return (
    <Grid className="pt-4" container>
      <Grid className="py-2 pr-1" item xs={6} container justifyContent="flex-end">
        <FormControl fullWidth>
          <InputLabel id="report-name-label">Report Name</InputLabel>
          <Select
            labelId="report-name-label"
            label="Report Name"
            value={reportName}
            onChange={event => onReportChange(event.target.value)}
          >
            {
              config.reports.map(report => (
                <MenuItem value={report.name}>{report.label}</MenuItem>
              ))
            }
          </Select>
        </FormControl>
      </Grid>

      <Grid className="py-2 pl-1" item xs={6}>
        <TextField
          fullWidth
          id="searchValue"
          label="Find report events"
          onChange={event => setSearchValue(event.target.value)}
          value={searchValue}
          InputProps={{
            endAdornment: (
              <InputAdornment>
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      {loading && (
        <Loader />
      )}

      {!loading && (
        <Grid className="pt-3" item xs={12} container justifyContent="flex-end">

          {reportName === 'diff' && (
            <Grid>
              <style>{diffStyles}</style>
              {filteredEvents.map(event => (
                fields.map(name => (
                  <DiffDisplayer diffText={String.raw`${event.message[name]}`} />
                ))
              ))}
            </Grid>
          )}

          {reportName !== 'diff' && (
            <Grid container>
              <Grid item xs={12}>
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow className={classes.tableHeadRow}>
                        {fields.map(name => {
                          const titleValue = titleCase(name.replaceAll('_', ' '));
                          if (titleValue === 'Content Is Already A Target Of A Conref Or Topicref') {
                            return <TableCell style={{ minWidth: '200px' }}>{titleValue}</TableCell>;
                          }

                          return <TableCell>{titleValue}</TableCell>;
                        })}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {!logsExist && (
                        <TableRow className={classes.tableRow}>
                          <TableCell>
                            {[
                              'reuse_report_exact_matches',
                              'reuse_report_fuzzy_matches',
                            ].includes(reportName) ? 'There are no matches to report' : 'There are no report data'}
                          </TableCell>
                        </TableRow>
                      )}

                      {logsExist && filteredEvents.map(event => (
                        <TableRow className={classes.tableRow} key={event.id}>
                          {fields.map(name => (
                            <TableCell component="th">{event.message[name].toString()}</TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          )}

          <Table size="small">
            {logsExist && (
              <TableRow className={classes.tableRow}>
                <TableCell colSpan={fields.length || 1}>
                  {!newerEventsExist && <div>There no newer events</div>}
                  {newerEventsExist && (
                    <div className="df-center-y">
                      <div>There are newer events to load.</div>
                      <LoadingLink loading={loadingNewerEvents} onClick={handleGetNewerEvents}>
                        Load more
                      </LoadingLink>
                    </div>
                  )}
                </TableCell>
              </TableRow>
            )}
          </Table>
        </Grid>
      )}
    </Grid>
  );
}

TaskDetailsReportLogs.propTypes = {
  task: PropTypes.oneOfType([
    PropTypes.instanceOf(TaskEntity),
    PropTypes.exact({}),
  ]).isRequired,
  config: PropTypes.instanceOf(Object).isRequired,
};

export default TaskDetailsReportLogs;
