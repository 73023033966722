import {
  TYPE_FLUID_TOPICS,
  TYPE_NORMALIZATION,
  TYPE_CONVERSION,
  TYPE_PANDOC,
  TYPE_PALIGO,
  TYPE_IXIASOFT,
  TYPE_SDL,
  TYPE_GITHUB,
  TYPE_KION_SDL,
  TYPE_BLUESTREAM,
} from '../constants';
import {
  TASK_CONVERTER_NORMALIZATION,
  TASK_ENGINE_PANDOC,
  TASK_ENGINE_INTEGRATOR,
} from '../entities';
import { formatDateStr, isInclude } from './commonService';

export const filterBySearch = (tasks, searchValue, filters) => {
  let filteredTasks = tasks.filter(
    task => isInclude(
      [task.id, task.source, task.converter, task.status, task.owner, task.tag].join('\n'),
      searchValue,
    ),
  );

  if (filters) {
    if (filters.statuses) {
      filteredTasks = filteredTasks.filter(t => filters.statuses.includes(t.status));
    }

    if (filters.dateFrom) {
      const date = typeof (filters.dateFrom) === 'string' ? new Date(filters.dateFrom) : filters.dateFrom;

      filteredTasks = filteredTasks.filter(
        t => new Date(t.created) >= new Date(date.getFullYear(), date.getMonth(), date.getDate()),
      );
    }

    if (filters.dateTo) {
      const date = typeof (filters.dateTo) === 'string' ? new Date(filters.dateTo) : filters.dateTo;

      filteredTasks = filteredTasks.filter(
        t => new Date(t.created) <= new Date(
          date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59,
        ),
      );
    }

    if (filters.types && filters.types.length > 0) {
      let conversions = [];
      if (filters.types.includes(TYPE_CONVERSION)) {
        conversions = filteredTasks.filter(task => (
          task.converter !== TASK_CONVERTER_NORMALIZATION
        && task.engine !== TASK_ENGINE_PANDOC
        && task.engine !== TASK_ENGINE_INTEGRATOR
        ));

        if (filters.conversionType) {
          conversions = conversions.filter(t => t.converter === filters.conversionType);

          if (filters.conversionTag) {
            conversions = conversions.filter(t => t.tag === filters.conversionTag);
          }
        }
      }

      let normalizations = [];
      if (filters.types.includes(TYPE_NORMALIZATION)) {
        normalizations = filteredTasks.filter(t => t.converter === TASK_CONVERTER_NORMALIZATION);

        if (filters.normalizationTag) {
          normalizations = normalizations.filter(t => t.tag === filters.normalizationTag);
        }
      }

      let pandocs = [];
      if (filters.types.includes(TYPE_PANDOC)) {
        pandocs = filteredTasks.filter(t => t.engine === TASK_ENGINE_PANDOC);
      }

      let paligo = [];
      if (filters.types.includes(TYPE_PALIGO)) {
        paligo = filteredTasks.filter(t => t.engine === TASK_ENGINE_INTEGRATOR && t.converter.startsWith('paligo'));
      }

      let ixiasoft = [];
      if (filters.types.includes(TYPE_IXIASOFT)) {
        ixiasoft = filteredTasks.filter(t => t.engine === TASK_ENGINE_INTEGRATOR && t.converter.startsWith('ixiasoft'));
      }

      let kionSdl = [];
      if (filters.types.includes(TYPE_KION_SDL)) {
        kionSdl = filteredTasks.filter(t => t.engine === TASK_ENGINE_INTEGRATOR && t.converter.startsWith('kion-sdl'));
      }

      let sdl = [];
      if (filters.types.includes(TYPE_SDL)) {
        sdl = filteredTasks.filter(t => t.engine === TASK_ENGINE_INTEGRATOR && t.converter.startsWith('sdl'));
      }

      let fluidTopics = [];
      if (filters.types.includes(TYPE_FLUID_TOPICS)) {
        fluidTopics = filteredTasks.filter(t => t.engine === TASK_ENGINE_INTEGRATOR && t.converter.startsWith('fluid-topics'));
      }

      let gitHub = [];
      if (filters.types.includes(TYPE_GITHUB)) {
        gitHub = filteredTasks.filter(t => t.engine === TASK_ENGINE_INTEGRATOR && t.converter.startsWith('github-'));
      }

      let bluestearm = [];
      if (filters.types.includes(TYPE_BLUESTREAM)) {
        bluestearm = filteredTasks.filter(t => t.engine === TASK_ENGINE_INTEGRATOR && t.converter.startsWith('bluestream-'));
      }

      filteredTasks = [
        ...normalizations,
        ...conversions,
        ...fluidTopics,
        ...bluestearm,
        ...ixiasoft,
        ...pandocs,
        ...kionSdl,
        ...gitHub,
        ...paligo,
        ...sdl,
      ];

      filteredTasks = filteredTasks.filter((value, index) => {
        const _value = JSON.stringify(value);
        return index === filteredTasks.findIndex(obj => JSON.stringify(obj) === _value);
      });
    }
  }

  return filteredTasks;
};

export const conversionTagTooltip = (value, items) => {
  const tag = items.find(i => i.name === value);

  if (!tag) return undefined;

  return (
    <>
      Last modified at:
      {' '}
      {formatDateStr(tag.updated)}
    </>
  );
};

export const displayTagValue = (value, items) => {
  const tag = items.find(i => i.name === value);

  if (!tag) return undefined;

  return (
    <>
      {value}
      {' '}
      <span style={{ fontWeight: '350' }}>{`(${formatDateStr(tag.updated)})`}</span>
    </>
  );
};
