import {
  ConversionTagEntity,
  ConversionTypeEntity,
  ErrorEntity,
  EventEntity,
  FileEntity,
  FolderEntity,
  TagEntity,
  TaskEntity,
  UploadURLEntity,
  ProgressEntity,
  IxiasoftDrmOjectEntity,
  IxiasoftUserEntity,
  IxiasoftStatusEntity,
  IxiasoftObjectTypeEntity,
  PaligoPublishSettingEntity,
  PaligoDocumentEntity,
  SdlFolderEntity,
  UserEntity,
  FileStorageConfigurationEntity,
  GitHubRepositoryEntity,
  GitHubBranchEntity,
  TaskStateEntity,
  MultipartUploadEntity,
  UploadPartUrlEntity,
  BlueStreamProjectEntitiy,
} from './entities';

export function parseBasePathEntity(obj) {
  return obj.type === 'folder' ? parseFolderEntity(obj) : parseFileEntity(obj);
}

export function parseFolderEntity(obj) {
  return new FolderEntity(
    obj.id,
    obj.basename,
    obj.attributes.dirname,
  );
}

export function parseFileEntity(obj) {
  return new FileEntity(
    obj.id,
    obj.attributes.basename,
    obj.attributes.filename,
    obj.attributes.size,
    obj.attributes.updated,
  );
}

export function parseEventEntity(obj) {
  return new EventEntity(
    obj.id,
    obj.attributes.timestamp,
    obj.attributes.message,
  );
}

export function parseTagEntity(obj) {
  return new TagEntity(
    obj.id,
    obj.attributes.key,
    obj.attributes.value,
    obj.attributes.path,
  );
}

export function parseTaskEntity(obj) {
  return new TaskEntity(
    obj.id,
    obj.attributes.engine,
    obj.attributes.converter,
    obj.attributes.tag,
    obj.attributes.owner,
    obj.attributes.source,
    obj.attributes.output,
    obj.attributes.status,
    obj.attributes.created,
    obj.attributes.started,
    obj.attributes.finished,
    obj.attributes.ram,
    obj.attributes.cpu,
    obj.attributes.config,
    obj.attributes.expected_result,
    obj.attributes.reports,
    JSON.parse(obj.attributes.env),
  );
}

export function parseTaskStateEntity(obj) {
  return new TaskStateEntity(
    obj.attributes.has_errors,
    obj.attributes.has_warnings,
  );
}

export function parseUploadURLEntity(obj) {
  return new UploadURLEntity(
    obj.id,
    obj.attributes.url,
    obj.attributes.bucket,
    obj.attributes.key,
    obj.attributes.expiration,
    obj.attributes.created,
    obj.attributes.access_key_id,
    obj.attributes.policy,
    obj.attributes.signature,
    obj.attributes.x_amz_security_token,
  );
}

export function parseErrorEntity(obj) {
  return new ErrorEntity(
    parseInt(obj.status, 10),
    obj.detail,
  );
}

export function parseConversionTypeEntity(engine, obj) {
  return new ConversionTypeEntity(
    engine,
    obj.attributes.dirname,
  );
}

export function parseConversionTagEntity(nameParser, obj) {
  return new ConversionTagEntity(
    nameParser(obj.attributes.filename),
    obj.attributes.updated,
  );
}

export function parseProgressEntity(obj) {
  return new ProgressEntity(
    obj.attributes.progress,
    obj.attributes.message,
  );
}

export function parseIxiasoftDrmOjectEntity(obj) {
  return new IxiasoftDrmOjectEntity(
    obj.attributes.path,
    obj.attributes.title,
    obj.attributes.children.map(parseIxiasoftDrmOjectEntity),
  );
}

export function parseIxiasoftUserEntity(obj) {
  return new IxiasoftUserEntity(
    obj.attributes.email,
    `${obj.attributes.first_name} ${obj.attributes.last_name}`,
    obj.attributes.domain,
    obj.attributes.username,
  );
}

export function parseIxiasoftStatusEntity(obj) {
  return new IxiasoftStatusEntity(
    obj.attributes.status,
  );
}

export function parseIxiasoftObjectTypeEntity(obj) {
  return new IxiasoftObjectTypeEntity(
    obj.type,
    obj.attributes.value,
  );
}

export function parsePaligoPublishSettingEntity(obj) {
  return new PaligoPublishSettingEntity(
    obj.attributes.id,
    obj.attributes.name,
    obj.attributes.format,
    obj.attributes.resource,
    obj.attributes.created_at,
    obj.attributes.modified_at,
  );
}

export function parsePaligoDocumentEntity(obj) {
  return new PaligoDocumentEntity(
    obj.attributes.id,
    obj.attributes.name,
    obj.attributes.link,
  );
}

export function parseSdlFolderEntity(obj) {
  return new SdlFolderEntity(
    obj.attributes.name,
    obj.attributes.folder_ref,
  );
}

export function parseUserEntity(obj) {
  return new UserEntity(
    obj.id,
    obj.attributes.email,
    obj.attributes.status,
    obj.attributes.enabled,
    obj.attributes.created_at,
    obj.attributes.updated_at,
  );
}

export function parseFileStorageConfigurationEntity(obj) {
  return new FileStorageConfigurationEntity(
    obj.attributes.allows_to_delete,
    obj.attributes.protected_folders,
  );
}

export function parseGitHubRepositoryEntity(obj) {
  return new GitHubRepositoryEntity(
    obj.attributes.id,
    obj.attributes.name,
  );
}

export function parseGitHubBranchEntity(obj) {
  return new GitHubBranchEntity(
    obj.attributes.name,
  );
}

export function parseBlueStreamProjectEntity(obj) {
  return new BlueStreamProjectEntitiy(
    obj.attributes.project_id,
    obj.attributes.project_name,
    obj.attributes.branch_id,
    obj.attributes.branch_name,
    obj.attributes.language_id,
    obj.attributes.language_code,
  );
}

export function parseMultipartUploadEntity(obj) {
  return new MultipartUploadEntity(
    obj.attributes.path,
    obj.attributes.upload_id,
    obj.attributes.created_at,
  );
}

export function parseUploadPartUrlEntity(obj) {
  return new UploadPartUrlEntity(obj.attributes.url);
}
