import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  MenuItem,
  MenuList,
  Popper,
  Paper,
  Grow,
  Box,
  Card,
  Grid,
  Link,
  IconButton,
  ClickAwayListener,
  Typography,
  TextField,
  InputAdornment,
  Button,
} from '@material-ui/core';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import { RiArrowDownSFill } from 'react-icons/ri';
import { HiOutlineSearch } from 'react-icons/hi';
import { IoIosAddCircle } from 'react-icons/io';
import { AiOutlineMenu } from 'react-icons/ai';
import { userSignOut } from '../../redux/actions/authActions';
import { CREATE_TASK_ROUTE, TASKS_ROUTE } from '../../core/constants';
import LatestTasks from './LastestTasks';
import { MENU_CLOSE, MENU_OPEN } from '../../redux/actionTypes';
import {
  MAIN_UI_COLOR, ENABLED_TASKS, CONVERSION_TITLE, ENABLE_OLD_CREATE_CONVERSION,
} from '../../config';
import CreateNormalizationDialog from '../tasks/CreateNormalizationDialog';
import CreateConversionDialog from '../tasks/CreateConversionDialog';
import CreatePandocDialog from '../tasks/CreatePandocDialog';
import CreatePaligoDialog from '../tasks/CreatePaligoDialog';
import CreateIxiasoftDialog from '../tasks/CreateIxiasoftDialog';
import CreateSdlDialog from '../tasks/CreateSdlDialog';
import Loader from '../common/Loader';
import CreateFluidTopicsDialog from '../tasks/CreateFluidTopicsDialog';
import CreateGitHubDialog from '../tasks/CreateGitHubDialog';
import CreateKionSdlDialog from '../tasks/CreateKionSdlDialog';
import CreateBlueStreamDialog from '../tasks/CreateBlueStreamDialog';

const createLinks = [
  {
    enabled: ENABLED_TASKS.conversion,
    text: CONVERSION_TITLE,
    key: 'conversion',
  },
  {
    enabled: ENABLED_TASKS.normalization,
    text: 'Normalization',
    key: 'normalization',
  },
  {
    enabled: ENABLED_TASKS.pandoc,
    text: 'Pandoc',
    key: 'pandoc',
  },
  {
    enabled: ENABLED_TASKS.ixiasoft,
    text: 'Ixiasoft',
    key: 'ixiasoft',
  },
  {
    enabled: ENABLED_TASKS.paligo,
    text: 'Paligo',
    key: 'paligo',
  },
  {
    enabled: ENABLED_TASKS.kion_sdl,
    text: 'Kion Tridion',
    key: 'kion_sdl',
  },
  {
    enabled: ENABLED_TASKS.sdl,
    text: 'Tridion',
    key: 'sdl',
  },
  {
    enabled: ENABLED_TASKS.fluidTopics,
    text: 'Fluid Topics',
    key: 'fluidTopics',
  },
  {
    enabled: ENABLED_TASKS.gitHub,
    text: 'GitHub',
    key: 'gitHub',
  },
  {
    enabled: ENABLED_TASKS.bluestream,
    text: 'BlueStream XDocs',
    key: 'bluestream',
  },
];

function Dashboard() {
  const history = useHistory();
  const dispatch = useDispatch();

  const { user, loading } = useSelector(state => state.iam.current_user);

  const isMobile = useSelector(state => state.app.isMobile);
  const isMenuOpen = useSelector(state => state.app.isMenuOpen);

  const [openSdlTaskDialog, setOpenSdlTaskDialog] = useState(false);
  const [openKionSdlTaskDialog, setOpenKionSdlTaskDialog] = useState(false);

  const [openPandocTaskDialog, setOpenPandocTaskDialog] = useState(false);
  const [openPaligoTaskDialog, setOpenPaligoTaskDialog] = useState(false);
  const [openCreateTaskDialog, setOpenCreateTaskDialog] = useState(false);
  const [openGitHubTaskDialog, setOpenGitHubTaskDialog] = useState(false);
  const [openIxiasoftTaskDialog, setOpenIxiasoftTaskDialog] = useState(false);
  const [openBlueStreamTaskDialog, setOpenBlueStreamTaskDialog] = useState(false);
  const [openFluidTopicsTaskDialog, setOpenFluidTopicsTaskDialog] = useState(false);
  const [openNormalizationTaskDialog, setOpenNormalizationTaskDialog] = useState(false);

  const signOut = () => {
    dispatch(userSignOut());
    history.push('/');
  };

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleListKeyDown = event => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  const createTaskButtonClick = key => {
    switch (key) {
      case 'conversion': {
        if (ENABLE_OLD_CREATE_CONVERSION) history.push(CREATE_TASK_ROUTE);
        else setOpenCreateTaskDialog(true);
        break;
      }
      case 'normalization': {
        setOpenNormalizationTaskDialog(true);
        break;
      }
      case 'pandoc': {
        setOpenPandocTaskDialog(true);
        break;
      }
      case 'paligo': {
        setOpenPaligoTaskDialog(true);
        break;
      }
      case 'ixiasoft': {
        setOpenIxiasoftTaskDialog(true);
        break;
      }
      case 'kion_sdl': {
        setOpenKionSdlTaskDialog(true);
        break;
      }
      case 'sdl': {
        setOpenSdlTaskDialog(true);
        break;
      }
      case 'fluidTopics': {
        setOpenFluidTopicsTaskDialog(true);
        break;
      }
      case 'gitHub': {
        setOpenGitHubTaskDialog(true);
        break;
      }
      case 'bluestream': {
        setOpenBlueStreamTaskDialog(true);
        break;
      }
      default:
        break;
    }
  };

  const handleCreationForm = (changeValueMethod, link) => {
    changeValueMethod(false);

    if (link) history.push(link);
  };

  if (loading) return <Loader />;

  return (
    // TODO: remove style
    <div style={{ marginLeft: '60px', marginRight: '60px', marginTop: '48px' }}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Typography
            variant="h4"
            style={{
              alignItems: 'center',
              display: 'flex',
              fontWeight: 500,
              lineHeight: 1,
            }}
          >
            {(!isMenuOpen || isMobile) && (
              <IconButton
                style={{
                  fontSize: '2.125rem',
                  marginRight: '12px',
                  color: MAIN_UI_COLOR,
                  padding: 0,
                }}
                onClick={() => dispatch({ type: !isMenuOpen ? MENU_OPEN : MENU_CLOSE })}
              >
                <AiOutlineMenu />
              </IconButton>
            )}
            Dashboard
          </Typography>
        </Grid>

        <Grid item xs={6}>
          {!loading && (
            <Box
              style={{
                justifyContent: 'flex-end',
                alignItems: 'center',
                display: 'flex',
                height: '100%',
              }}
            >
              <Link
                style={{ textTransform: 'none', color: MAIN_UI_COLOR }}
                aria-controls={open ? 'menu-list-grow' : undefined}
                onClick={handleToggle}
                aria-haspopup="true"
                ref={anchorRef}
              >
                <Typography
                  style={{
                    color: MAIN_UI_COLOR,
                    fontSize: '18px',
                    fontWeight: 500,
                  }}
                >
                  {user.id}
                  {' '}
                  <RiArrowDownSFill />
                </Typography>
              </Link>
            </Box>
          )}

          <Popper
            anchorEl={anchorRef.current}
            style={{ zIndex: 10 }}
            role={undefined}
            disablePortal
            open={open}
            transition
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList key="profileList" autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                      <MenuItem key="profile" onClick={handleClose}>Profile</MenuItem>
                      <MenuItem key="signOut" onClick={signOut}>Sign out</MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Grid>

        <Grid item xs={12} className="pt-4">
          <Box sx={{ boxShadow: 0 }}>
            <TextField
              placeholder="Filter tasks"
              InputProps={{
                style: { paddingTop: '2.5px', paddingBottom: '2.5px' },
                startAdornment: (
                  <InputAdornment position="start">
                    <HiOutlineSearch style={{ color: 'grey' }} />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              size="small"
              id="search"
              fullWidth
            />
          </Box>
        </Grid>

        <Grid item xs={12} className="pt-3">
          <Grid container spacing={3}>

            {createLinks.filter(i => i.enabled).map(link => (
              <Grid key={link.key} item xs={6} sm={4} md={3} lg={2}>
                <Card>
                  <Button
                    onClick={() => createTaskButtonClick(link.key)}
                    style={{
                      justifyContent: 'flex-start',
                      textTransform: 'none',
                      paddingBottom: '14px',
                      paddingTop: '14px',
                      color: '#333333',
                      fontSize: '16px',
                      fontWeight: 500,
                    }}
                    fullWidth
                  >
                    <IoIosAddCircle style={{
                      marginRight: '0.75rem',
                      marginLeft: '0.75rem',
                      color: MAIN_UI_COLOR,
                      fontSize: '20px',
                    }}
                    />
                    {link.text}
                  </Button>
                </Card>
              </Grid>
            ))}

          </Grid>
        </Grid>

        <Grid item xs={12} className="pt-4">
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Typography variant="h5" style={{ fontWeight: 500 }}>
                {`${user ? user.id : ''} latest tasks`}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Box
                style={{
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  display: 'flex',
                  height: '100%',
                }}
              >
                <Link to={TASKS_ROUTE} component={RouterLink}>
                  <Typography
                    style={{
                      textDecoration: 'underline',
                      fontSize: '0.875rem',
                      color: MAIN_UI_COLOR,
                      fontWeight: 700,
                    }}
                  >
                    VIEW ALL TASKS
                  </Typography>
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} className="pb-4">
          <LatestTasks />
        </Grid>

      </Grid>

      {openNormalizationTaskDialog && (
        <CreateNormalizationDialog
          rerunTask={{}}
          open={openNormalizationTaskDialog}
          onClose={link => handleCreationForm(setOpenNormalizationTaskDialog, link)}
        />
      )}

      {openCreateTaskDialog && (
        <CreateConversionDialog
          rerunTask={{}}
          open={openCreateTaskDialog}
          onClose={link => handleCreationForm(setOpenCreateTaskDialog, link)}
        />
      )}

      {openPandocTaskDialog && (
        <CreatePandocDialog
          rerunTask={{}}
          open={openPandocTaskDialog}
          onClose={link => handleCreationForm(setOpenPandocTaskDialog, link)}
        />
      )}

      {openPaligoTaskDialog && (
        <CreatePaligoDialog
          rerunTask={{}}
          open={openPaligoTaskDialog}
          onClose={link => handleCreationForm(setOpenPaligoTaskDialog, link)}
        />
      )}

      {openIxiasoftTaskDialog && (
        <CreateIxiasoftDialog
          rerunTask={{}}
          open={openIxiasoftTaskDialog}
          onClose={link => handleCreationForm(setOpenIxiasoftTaskDialog, link)}
        />
      )}

      {openKionSdlTaskDialog && (
        <CreateKionSdlDialog
          rerunTask={{}}
          open={openKionSdlTaskDialog}
          onClose={link => handleCreationForm(setOpenKionSdlTaskDialog, link)}
        />
      )}

      {openSdlTaskDialog && (
        <CreateSdlDialog
          rerunTask={{}}
          open={openSdlTaskDialog}
          onClose={link => handleCreationForm(setOpenSdlTaskDialog, link)}
        />
      )}

      {openFluidTopicsTaskDialog && (
        <CreateFluidTopicsDialog
          rerunTask={{}}
          open={openFluidTopicsTaskDialog}
          onClose={link => handleCreationForm(setOpenFluidTopicsTaskDialog, link)}
        />
      )}

      {openGitHubTaskDialog && (
        <CreateGitHubDialog
          rerunTask={{}}
          open={openGitHubTaskDialog}
          onClose={link => handleCreationForm(setOpenGitHubTaskDialog, link)}
        />
      )}

      {openBlueStreamTaskDialog && (
        <CreateBlueStreamDialog
          rerunTask={{}}
          open={openBlueStreamTaskDialog}
          onClose={link => handleCreationForm(setOpenBlueStreamTaskDialog, link)}
        />
      )}
    </div>
  );
}

export default Dashboard;
